// Update most telephone numbers on the page with tfn if its present
var queryString = window.location.search;

if (queryString) {
  var label = null;
  var items = null;
  var urlParams = new URLSearchParams(queryString);
  var tfn = urlParams.get('tfn');
  var match = tfn ? tfn.match(/^(\d{3})(\d{3})(\d{4})$/) : "";
  var x=document.getElementsByClassName("tfn");  // Find the elements

  if (match.length != 0) {
    for(var i = 0; i < x.length; i++){
      x[i].innerText='1-' + match[1] + '-' + match[2] + '-' + match[3];    // Change the content
      x[i].setAttribute('href','tel:' + tfn);
      label = x[i].getAttribute('data-label');
      if (label) {
        items = label.split("–");
        items[2] = tfn;
        label = items.join("–");
        x[i].setAttribute('data-label',label);
      }
    }
  } else {
    for(var i = 0; i < x.length; i++){
      label = x[i].getAttribute('data-label');
      if (label) {
        items = label.split("–");
        items[3] = items[2];
        items[2] = 'DT';
        label = items.join("–");
        x[i].setAttribute('data-label',label);
      }
    }
  }
} else {
  // Defaults
  var x=document.getElementsByClassName("tfn");
  for(var i = 0; i < x.length; i++){
      label = x[i].getAttribute('data-label');
      if (label) {
        items = label.split("–");
        items[3] = items[2];
        items[2] = 'DT';
        label = items.join("–");
        x[i].setAttribute('data-label',label);
      }
    }
}